@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Georgia:wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

#B91F1A4B0{
display: none;
}

button.cancelBtn {
  text-transform: uppercase;
  align-items: center;
  border: none;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  /* color: #5f6368; */
  cursor: pointer;
  height: 36px;
  min-width: 80px;
  outline: none;
  padding: 0 16px;
  background-color: #e25254;
  /* color: #fff!important; */
}

tr.table_content_header {
  border: 0px;
  background-color: #EEF2F5;
  padding: 52px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
}
.table_content_header td {
  color: #35414D;
  border: 0px;
  padding: 15px 0px;
  text-align: center;
}

button.saveBtn {
  text-transform: uppercase;
  align-items: center;
  border: none;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  /* color: #5f6368; */
  cursor: pointer;
  height: 36px;
  /* min-width: 80px; */
  outline: none;
  padding: 0 16px;
  background-color: #3373e9;
  /* color: #fff!important; */
}

.text-center>h2 {
  font-size: 18px;
}

.text-center>.text-danger {
  width: 22px !important;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0px solid !important;
  border-color: #d8dbe0;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0px solid;
  border-color: #d8dbe0;
}

.modal.show .modal-dialog {
  transform: none;
  width: 100%;
}

.sessions:hover {
  cursor: pointer;
}

.bg-black {
  border-bottom: 1px solid #ccc;
  color: #00356d;
  font-size: 14px;
}

.dropdown-header {
  color: #3c4b64!important;
  font-size: 15px!important;
}

.root_container {
  background: rgb(255, 255, 255);
  display: block;
  /* margin: -100px auto 0.5cm; */
  /* zoom: 70%; */
  padding: 20px;
  font-stretch: inherit;
}

.katB_log_lpQ .modal-dialog {
  max-width: 800px!important;
}

.katB_log_lpQ .modal-header {
  padding: 15px 20px;
  background-color: #4d88ff!important;
}

.user_log_Ktitle {
  font-weight: 400;
  color: #fff;
  margin-bottom: 0px;
}

.ps__rail-y {
  width: 10px!important;
  border-radius: 6px!important;
}

.ps__thumb-y {
  width: 6px!important;
}

p.page-link {
  cursor: pointer!important;
}

p.disabled.page-link {
  cursor: no-drop!important;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  font-size: medium;
}

/*
.c-sidebar-nav-link{
    padding-left: 40px !important;
} */

.c-sidebar {
  width: 265px !important;
  background: #00356d!important;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: rgb(45 123 206)!important;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #002a57!important;
}

.Toastify__toast {
  box-shadow: 0 0 10px #999 !important;
}

.Toastify__toast--success {
  background: green!important;
  border-radius: 3px!important;
}

.Toastify__toast-container--top-right {
  top: 0.4em!important;
  right: 0.5em!important;
}

.Toastify__toast-container {
  min-width: 365px!important;
  max-width: 500px!important;
}

.ctPGeG {
  font-size: 15px!important;
}

.qdDSL {
  font-size: 15px!important;
  position: inherit!important;
}

.ViewContainer {
  font-family: "Open Sans", sans-serif;
}

/* width */

.contentView_scroll::-webkit-scrollbar {
  width: 0px !important;
}

/* Track */

.contentView_scroll::-webkit-scrollbar-track {
  background: #fff!important;
}

.contentView_scroll::-webkit-scrollbar-track:hover {
  background: #fff!important;
}

/* Handle */

.contentView_scroll::-webkit-scrollbar-thumb {
  background: #676565 !important;
  border-radius: 6px !important;
}


/* video player disable download */

video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

/* Handle on hover */

.contentView_scroll::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px #aaa !important;
  border-radius: 6px !important;
}

.pointer:hover {
  cursor: pointer;
}

.exitBtn:hover {
  background-color: #00356d;
  color: white;
}

/* ANCHOR  exitbtn design changed for content videos date:09-04-2021*/

.exitBtn2 {
  background-color: #00356d;
  color: white;
}

.btn-content {
  --c: goldenrod;
  color: var(--c);
  font-size: 16px;
  border: 0.3rem solid var(--c);
  border-radius: 0.5rem;
  width: 12em;
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

.ytp-gradient-top {
  display: none !important;
}

.ytp-overflow-icon {
  display: none !important;
}

mark {
  background-color: orange;
  color: black;
}

.css-2613qy-menu {
  color: #000!important;
  display: block!important;
}

/* li {
  list-style-type: none !important;
} */

[data-title]:hover::after {
  color: #fff !important;
  background-color: #000 !important;
  height: 90vf;
}

.tox-notifications-container {
  display: none;
}

.searchLabel {
  font-weight: 500;
  color: #000;
  font-size: 18px;
}

.searchContent {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

.MuiPaginationItem-page {
  color: #fff!important;
}

.MuiPaginationItem-icon {
  color: #fff!important;
}

.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer!important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  /* margin: 0px 0!important; */
  margin-top: 5px!important;
}

#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
  color: #fff;
  top: 5px;
}

.all_gallery_list::-webkit-scrollbar {
  width: 0px !important;
  height: 8px;
}

::-webkit-scrollbar {
  width: 0px !important;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #aaa !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.goog-te-combo {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(0, 53, 109);
  padding: 5px 0px;
}

.talkify-control-center .talkify-download-button {
  display: none;
}

.talkify-control-center .talkify-brand {
  display: none;
}

.talkify-control-center label {
  margin-bottom: 0px!important;
}
.talkify-control-center.local i {
  color: #fff!important;
}

.talkify-controlcenter-wrapper .talkify-control-center {
  background-color: #696c6e;
}

div#goog-gt-tt {
  display: none!important;
}

.talkify-controlcenter-wrapper {
  left: 35%!important;
  top: 8px!important;
  overflow: visible;
  /* transform: rotate(90deg);
  transform-origin: 50% 50%; */
}

.talkify-enhanced-word-highligher {
  z-index: 99999999!important;
}
.talkify-more-settings {
  /* transform: rotate(-90deg);
  transform-origin: 50% 50%;
  left: 42%!important;
  top: 3.7rem!important;
  width: 70%!important; */
}

.talkify-control-center.local > div > * {
  border-bottom: 1px solid #eee!important;
}

.talkify-control-center.local > div > * {
  padding: 5px 10px !important;
}

.talkify-control-center .talkify-audio-loading {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  padding-top: 10px;
}

.talkify-control-center button {
  /* transform: rotate(-90deg);
  transform-origin: 50% 50%;
  padding-top: 10px; */
}

.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
  border: 0px!important;
  font-size: 16px;
}
.Tam1 .goog-te-gadget {
  /* color: #294d4a!important; */
  color: #fff!important;
  font-family: 'Inter';
}
.Tel1 .goog-te-gadget {
  color: #058ED9!important;
}
.Comm1 .goog-te-gadget {
  /* color: #00356d!important; */
  color: #ffffff!important;
}

.goog-te-gadget {
  /* margin-top: 5px; */
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none!important;
}
.goog-te-gadget span {
  display: none;
}

.goog-te-banner-frame {
  display: none;
}

@media print {
  #google_translate_element {
  display: none;
  }
  }

/* .skiptranslate {
  display: none;
} */

/* Extra Things */

body {
  /* background: #eee; */
  background: #eef2f5!important;
  font-family: 'Open Sans', sans-serif;
  top: 0px!important;
}
.card {
  border: 0px!important;
  /* background: #eef2f5!important; */
  background: inherit!important;
}

.background-white {
  background-color: #fff!important;
}
.background-black {
  background-color: #000!important;
}

h3 {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin-top: 50px;
}

h3 i {
  color: #444;
}

.rhap_container {
  border: '4px solid #00356D !important';
}

.cursor {
  cursor: pointer;
}

.noResize {
  resize: none !important;
}

/*
.react-datepicker-ignore-onclickoutside {
  width: 570px !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.react-datepicker__input-container>input {
  width: 570px !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
} */

/* .tableExample {
  margin: 0, 20, 0, 80,
}
.tableHeader {
  margin: 12;
  color: 'white';
}

.tableData{
  margin: 12;
} */

.tableHeader {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 300px;
}

.tableHeader td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableHeader tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableHeader tr:hover {
  background-color: #ddd;
}

.tableHeader th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3c4b64;
  color: white;
}

#styledHeaderLink {
  margin-top: 2rem;
}

#styledTable {
  border-collapse: collapse;
  width: auto;
}

#styledTable caption {
  text-align: left;
  margin: 2rem 0 1rem 0;
  font-weight: bold;
}

#styledTable td, #styledTable th {
  border: 0.0625rem solid #ddd;
  padding: 0.75rem;
}

#styledTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#styledTable tr:hover {
  background-color: #ddd;
}

#styledTable th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  background-color: #0f4871;
  color: white;
}

#styledTable tfoot {
  text-align: right;
}

@media print {
  header, .buttonContainer {
    display: none;
  }
}

.button {
  width: 98%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: bolder;
  color: #fff !important;
  background-color: #00356D!important;
  border: none;
  /* border-radius: 10px; */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 1%;
  padding: 10px 10px !important;
  text-align: center;
}

.button:hover {
  /* background-color: #00356D!important;
  box-shadow: 0px 15px 20px rgba(133, 138, 230);
  color: #fff!important;
  transform: translateY(-7px); */
}

.btnactive {
  background-color: #701ff3;
  box-shadow: 0px 15px 20px rgba(133, 138, 230);
  color: #fff;
  transform: translateY(-7px);
}

.btn-outline-info {
  background-color: #39f!important;
  border-color: #39f!important;
  color: #fff!important;
}

.btn-outline-info:hover {
  background-color: #0d86ff!important;
  border-color: #0d86ff!important;
}

.katBtn_A_unbXQ {
  margin-bottom: 20px;
}

.Btn_hgtq_VX {
  height: 80px;
  margin: 0px 0px 0px 0px;
}

.label_NWw_KBX {
  height: 100 fit-content;
  margin-top: 35px;
}

.Full_Nw_KHc_Z {
  height: auto;
  margin: 30px 0px 0px 0px;
  margin-bottom: 50px;
  padding: 10px 8px 0px 10px;
  color: #000;
}

.CreatDang_btn_mV {
  float: right;
}

.CreatSave_btn_PKmxW {
  float: right;
  margin-right: 2%;
}

@media screen and (max-width: 900px) and (min-width: 300px) {
  .Hgtbtn_Cont_NbcX {
    min-height: 400px;
  }
  .Btn_hgtq_VX {
    min-height: 260px;
  }
  .TeaCls_MnBook_IQ {
    height: 100px!important;
  }
}

.circle-tile {
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.circle-tile-heading {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto -40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  color: #fff;
  transition: all ease-in-out .3s;
  background: rgb(31, 70, 113);
}

.circle-tile-heading .fa {
  line-height: 80px;
}

.circle-tile-content {
  padding-top: 62px;
  background: rgb(31, 70, 113);
}

.circle-tile-number {
  color: #fff;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  height: 75px;
}

.circle-tile-footer {
  display: block;
  padding: 5px;
  color: rgba(255, 255, 255, 0.62)!important;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .3s;
}

.TeaCls_MnBook_IQ {
  height: 40px;
  color: #000;
  font-size: 23px;
}

.Book_List_N_QUnd {
  height: 70vh;
  overflow: auto;
}

.New_ViewTech_Inq_P {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Tech_SSec_InvM {
  height: auto;
  color: #000;
  margin-bottom: 40px;
}

.modal-header {
  background-color: #001b41!important;
}

.modal-header .close {
  color: #fff!important;
  opacity: 1!important;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: magenta !important;
}

.weekend-days {
  color: red !important;
}


.Action_CON_CEN{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.katCont_EBtn_IC {
  padding: 2px 3px;
  /* background-color: #028b72d4; */
  height: 30px;
  width: 30px;
  /* color: #fff; */
  /* margin: 1px 0px 0px 8px; */
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  /* float: left; */
}

.katCont_EBtn_TB {
  padding: 2px 3px;
  /* background-color: #028b72d4; */
  /* height: 30px; */
  /* width: 30px; */
  /* color: #fff; */
  /* margin: 1px 0px 0px 8px; */
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  /* float: left; */
}

.table-draft  {
  vertical-align: inherit !important;
  text-align: "center";
}

.additionalData .AddiContent_Text strong:nth-child(2) {
  /* font-weight: normal!important; */
}

.ubXM_ .AddiContent_Text strong:nth-child(2) {
  /* font-weight: normal!important; */
}

.additionalData .AddiContent_Text_Fold strong:nth-child(2) {
  /* font-weight: normal!important; */
}

.ubXM_ .AddiContent_Text_Fold strong:nth-child(2) {
  /* font-weight: normal!important; */
}

.draft-table-header > th {
  text-align: center !important;
  vertical-align: middle !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: inherit!important;
  position: inherit!important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: inherit!important;
}
.css-15v22id-MuiAccordionDetails-root {
  padding: 0px!important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px!important;
}


/* tox tox-tinymce tox-tinymce--toolbar-bottom */
.toolbar__primary,.tox-toolbar__primary{
  border:none !important;
  outline: none !important;
  background-color: rgb(238, 242, 245) !important;
  border: none !important;
  /* border-radius: 15px !important; */
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.tox .tox-edit-area__iframe{
  border:none !important;
  outline: none !important;
  background-color: rgb(238, 242, 245) !important;
  border: none !important;
  /* border-radius: 15px !important; */
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.tox-tinymce{
  border:none !important;
}

.School_Tooltip {
    max-width: 300px;
    text-align: center;
    position: absolute;
    right: 1%;
    display: flex;
    flex-direction: column;
    z-index: 99999999999;
}

.School_Tooltip_Cube {
    display:flex;
    justify-content:flex-end;
    margin-right: 30px;
}


/* Style for Bookview List  */


.H_katbook {
  font-family: 'Inter';
  font-weight:600;
  font-size: 26px;
  color:black;
  /* font-weight:700; */
  /* Line-height:31.2px; */
}

.Card_Book_List {
  margin-top: 90px;
  /* height:270px; */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px!important;
  border: 1px solid #E6ECF1!important;
}


.Booklist_Subject {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 150%; */
  color:#1B252F;
  /* margin-top: 15px; */
}

.Container {
  display: flex;
  flex-wrap: wrap;
  
}

.Subject_container {
  padding: 20px 50px;
}

.Book_U_PC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 12px;
}

.BookOpen_Icon {
  width:18px;
  height:18px;
}

.Manoj_span {
  font-size:12px;
  font-weight:500;
}

.Margin_Left_Card {
  width: 260px;
  margin: 0px 15px;
  font-size: 20px;
}

.web-display-none {
  display:none
}

.background_white {
  background-color: #fff!important;
}

.Image_Size {
  width: 100%;
  height: 165px;
  margin-top: -55px;
  cursor: pointer;
}



.Portions_covered_Text_Container {
  /* width:33%; */
}

.Units_Count_Container {
  width:35%;
}


.Pad_Mar {
   padding: 10px 10px 0px 10px;
    margin-top: 16px;
}

.Image_Size:hover {
  box-shadow: 1px 2px 22px 3px #dadad9;
  /* border: 1px solid #E6ECF1; */
}

/* Style for Bookview List  */

.clarifyTxt_sty p {
  margin-bottom: 5px!important;
}
.ChatList_Uybv71L::-webkit-scrollbar {
  width: 5px !important;
}



/* Start Navbar Css */
.bg-3377FF{
  background-color:#3377FF !important;

}
.color-3377FF{
  color:#3377FF !important;
}

.color-66747F {
  color: #66747F!important;
}

.border-1px-3377FF{
  border:1px solid #3377FF;
}

.opacity-05{
  opacity: 0.5;
}

.opacity-1{
  opacity: 1;
}

/* End Navbar Css */

/* Start Multiple Css */

.assessment_back_icon {
  width: 24px;
  height: 24px;
  color: #66747F;
}
.assessment_subName {
  font-size: 12px;
  font-weight: 500;
}
.assessment_unitName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #35414D;
}

.clock_assessment {
  margin-top: 2px;
  width: 20px;
  height: 20px;
}

.clock_timer {
  font-family: Inter;
  font-style: normal;
  font-size: 16px;
  color: rgb(102, 116, 127);
  margin-bottom: 0px;
}

.assessment_finish_btn {
  border-radius: 30px;
  padding: 8px 31px;
  height: 40px;
  border: none;
  outline: none;
}
.assessment_finish_btn span {
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  width: 83px;
  height: 24px;
}

.QuestionType_Options {
  border-bottom: 1px solid #dee2e6!important;
  padding: 13px 12px;
}
.QuestionType_Options .icon {
  width: 20px;
  height: 20px;
}

.QuestionType_Options_list {
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 10px;
  z-index: 99999999;
  overflow: auto;
  width: 95%!important;
  background-color: rgb(255, 255, 255);
  top: 53px;
  left: 15px;
}

.questionData {
  padding: 25px 25px 0px 25px;
  border-left: 1px solid #ccc;
  overflow: auto;
}

.questionData h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: rgb(27, 37, 47);
}

.assessment_bottom_div {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  /* border-bottom: 1px solid #ccc; */
  border-top: 1px solid #ccc;
  padding: 10px 0px;
}

.assessment_sub_dev {
  display: flex;
  align-items: end;
  justify-content: end;
}
/* End Multiple Css */


/* start assessment progress bar css */

.checkmyProgress{
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.checkmyBar {
  height: 10px !important;
  border-radius: 50px;
  background-color: #06c270 !important;
}

.finishbtnfont{
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24PX;
  width: 83px;
  height: 24px;
  color: #FFFFFF;
}

.btnNormal{
  background-color: #ADB9C7;
  border-radius: 30px;
  color: #fff;
  padding: 8px 40px;
  height: 40px;
  border: none;
  outline: none;
}

.btnColor{
  background-color: #3377FF;
  border-radius: 30px;
  color: #fff;
  padding: 8px 40px;
  height: 40px;
  border: none;
  outline: none;
}

/* Default Question */
.border-1px-8F9CAA{
  border: 1px solid #8F9CAA !important;
}
.color-8F9CAA {
  color: #8F9CAA !important;
}

/* // Answerd Question

.border-1px-3377FF{
  border: 1px solid #3377FF,
}
.color-3377FF,{
  color: #3377FF,
} */


 /* Correct Question */

.border-1px-06C270{
  border: 1px solid #06C270 !important;
}
.color-fffff{
  color: #fff !important;
}
.bg-06C270{
 background-color: #06C270 !important
}

/*  Wrong Questin */

.border-1px-FF3B3B{
  border: 1px solid #FF3B3B !important;
}
.color-FF3B3B{
  color: #FF3B3B !important;
}

/* Correct Answer Radio */

.Correct-radio-answer{

  width: 15px;
  height: 15px;
  accent-color: green !important;

}

 /* Wrong Answer Radio */

.Wrong-radio-answer{
  accent-color: red !important;

  width: 15px;
  height: 15px;
}


.Correct-radio-option-dfjk > p > span {
  color: #06C270 !important;
}
.Wrong-radio-option-dfjk > p > span {
  color: #FF3B3B !important;
}
.true-answer-textbox{
  border: 1px solid #06C270 !important;
}
.false-answer-textbox{
  border: 1px solid #FF3B3B !important;
}

/* end assessment progress bar css */


/* start assessment results page */

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.2s;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.myProgress {
  width: 80% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
  float: left;
}

.myprogress_pertg {
  font-size: 12px;
  font-family: 'Inter';
  margin-top: 1.5%;
  }

  .myprogress_pertg span {
    margin-left: 3%;
  }

.myBar {
  height: 7px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%) !important;
}


.grennmyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.grennmyBar {
  height: 7px !important;
  border-radius: 50px !important;
  /* background-image: linear-gradient(to right, #20BF55, #01BAEF) !important; */
  background: linear-gradient(180deg, #13DA84 0%, #189C63 100%);
}


.orangemyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.orangemyBar {
  height: 7px !important;
  border-radius: 50px !important;
  /* background-image: linear-gradient(to right, #FC575E, #F7B42C) !important; */
  background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
}

.purplemyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.purplemyBar {
  height: 7px !important;
  border-radius: 50px !important;
  /* background-image: linear-gradient(to right, #5F0A87, #A4508B) !important; */
  background: linear-gradient(180deg, #C780FF 0%, #8B3DC9 100%);
}

.pinkmyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.pinkmyBar {
  height: 7px !important;
  border-radius: 50px;
  background: linear-gradient(180deg, #FF7AAA 0%, #E62168 100%);
}

.asst_result_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 35px 13px 35px;
  /* width: 39%; */
  border-bottom: 1.5px solid #EEF2F5;
  /* position: fixed; */
  /* top: 0px; */
  box-shadow: 0px 4px 20px rgb(0 0 0 / 6%);
  background-color: #fff;
}

.asst_result_txt {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: rgb(27, 37, 47);
}


.asst_result_list {
  /* margin-top: 55px; */
  overflow: auto;
  background-color: #fff;
}

.asst_attempts {
  padding: 10px 35px 10px 35px;
  border-bottom: 1.5px solid #EEF2F5;
}

.attempts_txt {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.QustT_data_Ipq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.quesT_data_img {
  width: 17px;
  height: 17px;
}

.quesT_data_name_PQi {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgb(143, 156, 170);
}

.View_ans_txt_pQI {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(76, 136, 255);
  cursor: pointer;
}

.progress_IMQU_P {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
/* end assessment results page */

/* start table of content table css */
.table_content_main {
  width: 100%;
  border: 1px solid #E6ECF1;
}

tr.table_content_header {
  border: 0px;
  background-color: #EEF2F5;
  padding: 52px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
}

.table_content_header td {
  color: #35414D;
  border: 0px;
  padding: 15px 0px;
}

.table_content_body {
  border: 0px;
  border-top: 1px solid #E6ECF1;
}

.table_content_body td {
  border: 0px;
  /* border-bottom: 1px solid #E6ECF1;
  border-top: 1px solid #E6ECF1; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #35414D;
}

.table-unit-name {
  color: #35414D!important;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}
.View-Table-session {
  display: none;
  border: 0px;
}
.View-Table-session div {
  padding: 5px;
}

.View-Table-session td {
  padding: 3px;
}

.innerTableDiv {
  color: #35414D!important;
  border: 0px;
}
.innerTableDiv td {
  border: 0px;
  text-align: left;
  padding: 5px 2px;
}

.innerTableDiv a {
  color: #35414D!important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.View-Table-session td {
  border: 0px;
}

.innerTable {
  text-align: center;
  padding: 0px;
  line-height: 30px;
  border: 0px;
  width: 100%;
  margin: 0px;
}

/* end table of content table css */



.align-third-container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
/* Media Query */

@media (min-width: 1000px) and (max-width:1120px) {
  .session_title_text{
    width:250px !important;
  }

 
}



/* Media Query End*/
.session_title_text{
  /* width:250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show_more_mobile{
  position:absolute;
  top: -40px;
  width:100vw;
  height:100vh;
  background-color:#FFFFFF;
}
.ChatList_Uybv71L{
  background-color: #FFFFFF !important;
}
.bg-FFF{
  background-color: #FFFFFF !important;
}

.lang_modbtn_skdjh{
  background: rgb(76, 136, 255);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content !important;
  padding: 12px 40px !important;
  border-radius: 18px !important;
  color: #FFFFFF !important
}
.lang_modbtncl_skdjh{
  background:#FFFFFF !important ;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content !important;
  padding: 12px 40px !important;
  border-radius: 18px !important;
  color:  rgb(76, 136, 255);
}

/* SAKTHI NAVEEN M 2022 */
.HeaderNavbar_school_tooltip{
  max-width : 300px;
  text-align: center;
  position: absolute;
  right: 1%;
  display: flex;
  flex-direction: column;
  z-index: 99999999999;
}


.HeaderNavbar_school_tooltip_tri{
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}


.SubHeaderNavbar_school_tooltip{
  max-width : 300px;
  text-align: center;
  position: absolute;
  right: 1%;
  display: flex;
  flex-direction: column;
  z-index: 99999999999;

}

.SubHeaderNavbar_school_tooltip_tri{
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}
.goog-te-gadget-simple {
  border: 0px!important;
}
.goog-te-menu-value span:nth-child(3) {
  display: none;
}

#google_translate_element {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Inter';
  margin: 0px 20px;
}
iframe.skiptranslate {
  visibility: hidden!important;
}
.goog-te-menu-frame {
  top: 56px!important;
}
div#google_translate_element select:nth-child(2) {
  display: none!important;
}