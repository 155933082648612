/* @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
	box-sizing: border-box;
} */

/* .progressBody {
	background-color: #f4f4f4;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	margin: 0;
} */

.progress {
	background-color: #fff;
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 5px;
	width: 300px;
}

.progress-done {
	background: linear-gradient(to left, #00356d, #00356d);
	/* box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C; */
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0; 
	transition: 1s ease 0.3s;
}